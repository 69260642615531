import React, { useState } from 'react';
import Layout from '../components/layout';
import Header from '../components/header';
import SEO from '../components/seo';
import { navigate } from 'gatsby';
import { ContactWrapper, ContactForm, MessageBox } from '../styles/blog';

export default function ContactPage({ location }) {

  if (typeof window !== 'undefined'){
    window.dataLayer.push({
      'event' : 'gtm.dom',
      'pageType' : 'Contato',
      'hostName' : 'blog.rocky.ag',
      'pagePath' : location.pathname,
      'pageReferrer' : document.referrer,
    });
  }

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [reason, setReason] = useState('')
  const [message, setMessage] = useState('')

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  function handleValidation() {
    if (typeof window !== 'undefined'){
      if (name === '') {
        window.dataLayer.push({
          'event' : 'error',
          'field' : 'name'
        });
      }
      else if(email === ''){
        window.dataLayer.push({
          'event' : 'error',
          'field' : 'email'
        });
      }
      else if (message === ''){
        window.dataLayer.push({
          'event' : 'error',
          'field' : 'message'
        });
      }
    }
  }

  function handleSubmit(e) {
    
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contato",
        "nome": name,
        "email": email,
        "motivo": reason,
        "menssagem": message
      })
    })
    .then(() => {
      navigate('/home/1?contact=success')
    })
  }

  return (

    <Layout>
      <SEO 
        title="Contato" 
      />
      <Header location={location} borderRadius={true}/>
      <ContactWrapper>
        <h1>Entre em contato com a gente!</h1>
        <h2>Deixe sua mensagem</h2>
        <ContactForm name="contato" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contato" required />
          <input type="text" onChange={(e) => setName(e.target.value)} name="nome" placeholder="Nome" required ></input>
          <input type="email" onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Email" required ></input>
          <select name="motivo" onChange={(e) => setReason(e.target.value)} required>
            <option value="none">Assunto</option>
            <option value="comercial">Comercial</option>
            <option value="parceria">Parceria</option>
          </select>
          <MessageBox type="text" onChange={(e) => setMessage(e.target.value)} name="menssagem" placeholder="Mensagem" required></MessageBox>
          <button type='submit' className="btn-contact" onClick={handleValidation}>Enviar</button>
        </ContactForm>
      </ContactWrapper>
    </Layout>
  )
}